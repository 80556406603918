import { Component, OnInit, Input } from '@angular/core';
import { ApidocsService } from 'src/app/services/apidocs.service';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { ReportService } from 'src/app/services/report.service';
import { JsonGenerator } from 'src/app/util/jsonGenerator'
import { of } from 'rxjs';
@Component({
  selector: 'app-apidoc',
  templateUrl: './apidoc.component.html',
  styleUrls: ['./apidoc.component.css']
})
export class ApidocComponent implements OnInit {
  JSON: any
  @Input()
  apidoc: any
  selectedApidoc: string;
  step = 0;
  stepStages = "";
  stages: any[]
  reportStages: any
  reportNames: any[]
  jsonGenerator: JsonGenerator
  jsonObjAPI: any
  pageBody: any
  addPageExample: any;
  addPageExampleById: any;
  addPageNote: any;
  dec_type: any
  apiReq: any
  selectedTab = 0

  constructor(public apidocService: ApidocsService, private route: ActivatedRoute, private reportService: ReportService) {
    this.JSON = JSON;
    this.reportNames = []
    this.reportStages = {}
    this.jsonGenerator = new JsonGenerator()
  }

  onSelect(apidoc: string) {
    this.selectedApidoc = apidoc;
  }

  isEmpty(obj) {
    for (var key in obj) {
      if (obj.hasOwnProperty(key))
        return false;
    }
    return true;
  }


  ngOnInit() {
    this.route.paramMap.pipe(switchMap((params: ParamMap) => {
      this.selectedApidoc = params.get('api');

      let requestedDoc = params.get('api')
      this.apiReq = params.get('api_single')
      if (params.get('declaration_type')) {
        this.dec_type = params.get('declaration_type')
        requestedDoc = 'declaration_single_page_key'
      }
      if (this.selectedApidoc === null) {
        this.step = 2;
      }
      for (let key in this.expPanel) {
        if (this.expPanel[key].find(elem => elem === this.selectedApidoc)) {
          this.step = parseInt(key);
        }
      }
      return this.apidocService.getApiDoc(requestedDoc)
    }))
      .subscribe(apidoc => {
        if (apidoc){
          this.apidoc = apidoc;
          this.addPageExample = this.apidoc['example']
          this.addPageExampleById = this.apidoc['example_by_id']
          this.addPageNote = this.apidoc['description']
          if (this.dec_type) {
            this.reportService.getReportPage(this.dec_type, this.apiReq).subscribe(stagePage => {
              if (!this.isEmpty(stagePage)) {
                this.selectedApidoc = stagePage.key
                var jsonObj = JSON.parse(JSON.stringify(stagePage))
                this.jsonObjAPI = this.jsonGenerator.generateAPI(jsonObj)
                this.apidoc['body_page'] = this.jsonObjAPI
                this.apidoc['title'] = stagePage.name
                this.apidoc['key'] = stagePage.key
                this.apidoc['example'] = this.addPageExample.replace('_decl_', this.dec_type).replace('_page_', this.apiReq)
                this.apidoc['example_by_id'] = this.addPageExampleById.replace('_id_', '5ca5fa0097e274456cf0ea67').replace('_page_', this.apiReq)
                this.apidoc['description'] = this.addPageNote.replace('_decl_', this.apiReq)
              }
            });
          }else{
            this.jsonObjAPI = this.apidoc['body']
          } 
        }
      });

    this.reportService.getReportsList().subscribe(reportTitleKeys => {
      reportTitleKeys.forEach(element => {
        this.reportNames.push({ key: element.key, name: element.name })
        this.reportService.getReportPage(element.key, '__all__').subscribe(
          data => {
            this.reportStages[element.key] = data
            for (var key in this.reportStages) {

              if (this.reportStages[key].find(elem => elem.key === this.apiReq)) {
                this.stepStages = key;
              }
            }
          },
          err => {
            console.log(err);
          }
        );
      });

    })
  }
  
  tabClick(event){
    this.selectedTab = event.index;
  }

  expPanel = {
    "0": ['login'],
    "1": ['addDeclaration', 'deleteDeclaration', 'getAllDeclarations', 'getAllDeclarationsByYear', 'getPartDeclarationsByYear'],
    "2": [''],
    "3": ['submitDeclaration']
  }

  copyText(val: string) {
    let selBox = document.createElement('textarea');

    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }

  private setting = {
    element: {
      dynamicDownload: null as HTMLElement
    }
  }

  apiData() {
    return of(
      this.jsonObjAPI
    );
  }
  downloadFile() {
    this.apiData().subscribe((res) => {
      this.dyanmicDownloadByHtmlTag({
        fileName: this.apidoc['key'] + '.json',
        text: JSON.stringify(res, null, "\t")
      });
    });
  }
  private dyanmicDownloadByHtmlTag(arg: {
    fileName: string,
    text: string
  }) {
    if (!this.setting.element.dynamicDownload) {
      this.setting.element.dynamicDownload = document.createElement('a');
    }
    const element = this.setting.element.dynamicDownload;
    const fileType = arg.fileName.indexOf('.json') > -1 ? 'text/json' : 'text/plain';
    element.setAttribute('href', `data:${fileType};charset=utf-8,${encodeURIComponent(arg.text)}`);
    element.setAttribute('download', arg.fileName);
    var event = new MouseEvent("click");
    element.dispatchEvent(event);
  }
}
