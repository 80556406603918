import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators'
import { SharedService } from './shared.service';
import { map } from "rxjs/operators";
import { Process } from "../entities/process"
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};


@Injectable({
  providedIn: 'root'
})
export class ProcessService {
  getEmmiterValue(customValueEmitter: any, processId: any) {
    return this.http.get<any>(this.sharedService.apiUrl('process/eventEmitterValue')+processId+'/'+customValueEmitter.stageKey+'/'+customValueEmitter.key).pipe(
      tap((evVal: any) => this.log(`getting eventEmmiterValue for process ${evVal}`)),
      catchError(this.handleError<any>('getting eventEmmiterValue'))
    );
  }
  getAvis(id: string): Observable<any> {
    let avises$ = this.http.get<any>(this.sharedService.apiUrl('process/total-fee'),
    {
      params: new HttpParams()
        .set('id', id)
    });
    return avises$;
  }
  getAttachments(processId: any, stageKey: any) {
    return this.http.get<any>(this.sharedService.apiUrl('process/attachments')+processId+'/'+stageKey).pipe(
      tap((attachments: any) => this.log(`getting attachments for process ${attachments}`)),
      catchError(this.handleError<any>('getting attachments'))
    );
  }
  
  downloadFileFromDocflow (name: any): Observable<Blob> {

    const httpOptions = {
      params: new HttpParams().set('name', name),
      responseType: 'blob' as 'json'
    };
    let process$ = this.http.get<any>(this.sharedService.apiUrl('process/docflowfile'), httpOptions).pipe(
      map((res: Response) => { return res}), catchError(this.handleError<any>('download docflow file'))
    )
    return process$;

  }
  downloadGeneratedFile (id: any, type: string): Observable<Blob> {
    const httpOptions = {
      params: new HttpParams().set('id', id),
      responseType: 'blob' as 'json'
    };
    let process$ = this.http.get<any>(this.sharedService.apiUrl('process/'+ type), httpOptions).pipe(
      map((res: Response) => { return res}), catchError(this.handleError<any>('download docflow file'))
    )
    return process$;

  }

  deleteFile (name: any): Observable<Blob> {

    const httpOptions = {
      params: new HttpParams().set('filename', name)
    };
    let process$ = this.http.delete<any>(this.sharedService.apiUrl('process/docflowfile'), httpOptions).pipe(
      map((res: Response) => { return res}), catchError(this.handleError<any>('delete docflow file'))
    )
    return process$;

  }

  deleteAttachment (prc: any): Observable<Blob> {
    const httpOptions = {
      params: new HttpParams().set('p_id', prc.p_id).set('p_stage', prc.p_stage).set('p_row', prc.p_row)
    };
    let process$ = this.http.delete<any>(this.sharedService.apiUrl('process/attachment'), httpOptions).pipe(
      map((res: Response) => { return res}), catchError(this.handleError<any>('delete process attachement'))
    )
    return process$;

  }

  constructor(private http: HttpClient, private sharedService: SharedService) { }
  addProcess(process: any): Observable<any> {
    return this.http.post<any>(this.sharedService.apiUrl('process/add'), process, httpOptions).pipe(
      tap((process: any) => this.log(`added process w/ id=${process.id}`)),
      catchError(this.handleError<any>('add'))
    );
  }

  delete(process: any): Observable<any> {
    return this.http.post<any>(this.sharedService.apiUrl('process/delete'), { 'id': process }, httpOptions).pipe(
      tap((process: any) => this.log(`deleted process w/ id=${process}`)),
      catchError(this.handleError<any>('delete process'))
    );
  }

  
  getProcessingStages(process: any): Observable<any> {
    return this.http.get<any>(this.sharedService.apiUrl('process/processing-stages')+process, httpOptions).pipe(
      tap((process: any) => this.log(`processsing stages w/ id=${process}`)),
      catchError(this.handleError<any>('processsing stages'))
    );
  }

  downloadPDF(report: any, processId: any): Observable<Blob> {

    const httpOptionsPDF = {
      headers: new HttpHeaders({ 'Accept': 'application/pdf', 'Content-Type': 'application/json' }),
      responseType: 'blob' as 'json',
      params: new HttpParams().set('report', report).set('id', processId)
    };
    let process$ = this.http.get<any>(this.sharedService.apiUrl('process/pdf'), httpOptionsPDF).pipe(
      map((res: Response) => res), catchError(this.handleError<any>('download pdf'))
    )
    return process$;

  }


  downloadFile(name: any): Observable<Blob> {
    const httpOptionsPDF = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      responseType: 'blob' as 'json',
      params: new HttpParams().set('file', name)
    };
    let process$ = this.http.get<any>(this.sharedService.apiUrl('process/file'), httpOptionsPDF).pipe(
      map((res: Response) => res), catchError(this.handleError<any>('download file'))
    )
    return process$;

  }

  downloadExcel(report: any, processId: any): Observable<Blob> {

    const httpOptions = {
      params: new HttpParams().set('report', report).set('id', processId),
      responseType: 'blob' as 'json'
    };
    let process$ = this.http.get<any>(this.sharedService.apiUrl('process/excel'), httpOptions).pipe(
      map((res: Response) => { return res}), catchError(this.handleError<any>('download docflow file'))
    )
    return process$;


  }

  uploadExternalReport(report: any, processId: any, mcasId: any): Observable<Blob> {
    const httpOptionsPDF = {
      headers: new HttpHeaders({ 'Accept': 'application/pdf', 'Content-Type': 'application/json' }),
      responseType: 'blob' as 'json',
      params: new HttpParams().set('report', report).set('id', processId).set('mcasId',mcasId)
    };
    let process$ = this.http.get<any>(this.sharedService.apiUrl('process/submitExternalReport'), httpOptionsPDF).pipe(
      map((res: Response) => res), catchError(this.handleError<any>('submit process'))
    )
    return process$;

  }

  downloadEditingPDF(stage: any): Observable<Blob> {
    const httpOptionsPDF = {
      headers: new HttpHeaders({ 'Accept': 'application/pdf', 'Content-Type': 'application/json' }),
      responseType: 'blob' as 'json'    
    };
    let process$ = this.http.post<any>(this.sharedService.apiUrl('process/pdfediting'), {stage:stage}, httpOptionsPDF).pipe(
      map((res: Response) => res), catchError(this.handleError<any>('download pdfediting'))
    )
    return process$;

  }


  getProcesses(year: string,
    filter = '', sortOrder = 'asc', sortColumn = '',
    pageNumber = 0, pageSize = 10): Observable<Process[]> {
    return this.http.get(this.sharedService.apiUrl('process'), {
      params: new HttpParams()
        .set('year', year)
        .set('filter', filter)
        .set('workspace', localStorage.getItem('default_workspace'))
        .set('sortOrder', sortOrder)
        .set('sortColumn', sortColumn.toString())
        .set('pageNumber', pageNumber.toString())
        .set('pageSize', pageSize.toString())
    }).pipe(
      map(res => {
        res['payload'] = res;
        return res["payload"];
      })
    );
  }

  getProcess(id: string): Observable<Process> {
    let process$ = this.http.get<Process>(this.sharedService.apiUrl('process/') + id)
    return process$;
  }
  getProcessByMcasReportId(mcasReportId: string): Observable<Process> {
    let process$ = this.http.get<Process>(this.sharedService.apiUrl('process/mcas_report_id') + mcasReportId)
    return process$;
  }
 
  getProcessMapping(processKey: string): Observable<Process> {
    let process$ = this.http.get<Process>(this.sharedService.apiUrl('process/mapping') + processKey)
    return process$;
  }

  submitProcess(processId: any): Observable<Blob> {

    const httpOptionsPDF = {
      headers: new HttpHeaders({ 'Accept': 'application/pdf', 'Content-Type': 'application/json' }),
      params: new HttpParams().set('id', processId)
    };
    let process$ = this.http.get<any>(this.sharedService.apiUrl('process/submit'), httpOptionsPDF).pipe(
      map((res: Response) => res), catchError(this.handleError<any>('submit process'))
    )
    return process$;

  }


  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  private log(message: string) {
    //this.messageService.add('HeroService: ' + message);
  }
}
