export class User {
    public username: string;
    public firstname: string;
    public lastname: string;
    public idnumber: string;
    public role: string;
    public apiEnabled: boolean;
    public password: string;
    public phonenumber: string;
    public countrycode: number;
}
