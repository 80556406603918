import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SharedService } from './shared.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AvisService {

  constructor(public http: HttpClient, public sharedService: SharedService) {

  }

 getAvisByRefNum(refNum: string){
  return this.http.get(this.sharedService.apiUrl('process/avisbyrefnum')+refNum).pipe(
    map(res => {
      return res;
    })
  );
 }

}



