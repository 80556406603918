import { Injectable } from "@angular/core";
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { JwtHelperService } from "@auth0/angular-jwt";
import { AuthService } from "../services/auth.service";
import { CompanyRepresentativeService } from "../services/companyRepresentative.service";
@Injectable({
  providedIn: "root",
})
export class AuthGuard implements CanActivate {
  constructor(private router: Router, public jwtHelper: JwtHelperService, public authService: AuthService, private companyRepresentativeService: CompanyRepresentativeService) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (next.queryParams.token_from_tas) {
      this.authService.validateTasToken(next.queryParams.token_from_tas, next.queryParams.username).then((res) => {
        
        if (res && res.usrToken) {

          localStorage.setItem("access_token", res.usrToken);
          setTimeout(() => {
            if (next.params && next.params.id){
              this.router.navigate(["process/"+next.params.id]);
            }else{
              this.router.navigate(['home']).then(() => {
                window.location.reload();
              });
            }
        
          }, 500);
          return true;
        }else{
          setTimeout(() => {
          localStorage.removeItem("access_token");
           window.location.reload();
          }, 500);
        }
        
      }
      );
    } else {
      if (next.queryParams.nonce) {
        this.router.navigate(["auth"], { queryParams: { nonce: next.queryParams.nonce, url: state.url.substring(0, state.url.indexOf("?")) } });
        return false;
      }
      var jwtRenewal = false;

      if (localStorage.getItem("access_token") && localStorage.getItem("access_token") !== "undefined") {
        if (this.jwtHelper.isTokenExpired()) {
          jwtRenewal = true;
          this.authService.requestToken(localStorage.getItem("access_token")).then((res) => {
            if (res === undefined) {
              this.authService.logout().subscribe((data) => {
                localStorage.removeItem("access_token");
                this.router.navigate(["auth"]);
              });
              return false;
            } else {
              localStorage.setItem("access_token", res);
              this.router.navigate([state.url]);
              return true;
            }
          });
        } else {
          //this.companyRepresentativeService.getCompanyRepresentative(this.jwtHelper.decodeToken(localStorage.getItem('access_token')).username).subscribe(result=>{
          return true;
          //})
        }
      }

      // this is the case when there is no access_token in browser's local storage
      if (!jwtRenewal) {
        this.router.navigate(["auth"], { queryParams: { url: state.url } });
        return false;
      }
    }
  }
}
